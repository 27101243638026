import { Link, Pane } from "evergreen-ui";
import fing from "../../assets/fing contorno.png";
import title from "../../assets/titulo-festival.png";
import subTitle from "../../assets/sub titulo festival.png";
import fecomercio from "../../assets/logos no retangulo.png";
import textBackground from "../../assets/texto-background.png";
import pointV from "../../assets/pontinhos veriticais linha.png";
import retangleV from "../../assets/retangulo cor solida.png";
import woman from "../../assets/mulher personagem.png";
import textCircle from "../../assets/texto bola cabeça.png";
import block from "../../assets/bloco pontinhos.png";

export const Init: React.FC = () => {
    return (
        <Pane
            id="home"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop={-55}
            className="snap-center image relative overflow-hidden"
        >
            <img
                src={title}
                alt="Fingapp Festival"
                className="absolute w-1/4 ajust-title"
            />
            <img
                src={subTitle}
                alt="Fingapp Festival"
                className="absolute w-1/5 sub-title"
            />
            <img
                src={fecomercio}
                alt="Fingapp Festival"
                className="absolute w-1/5 ajust-fecomercio"
            />
            <h1 className="absolute text-slate-800 font-bold event-date">Garanhuns | 7 de Dezembro</h1>
            <Link
                href="https://www.sympla.com.br/evento/festival-de-inovacao-e-negocios-de-garanhuns-fing/2721170"
                padding={4}
                paddingX={24}
                className="absolute z-10 button-mobile"
                style={{
                    color: "#f8fafc",
                    background: "#0d9488",
                    fontWeight: "bold",
                    textDecoration: "none"
                }}
            >
                Faça Sua Inscrição
            </Link>
            <img
                src={textBackground}
                alt="Fingapp Festival"
                className="absolute w-40 opacity-65 textBackground"
                style={{
                    left: "-10px",
                    top: "0",
                    zIndex: 0
                }}
            />
            <img
                src={block}
                alt="Fingapp Festival"
                className="absolute textBackground"
                style={{
                    left: "120px",
                    width: "19%",
                    top: "-24%",
                    zIndex: 0
                }}
            />
            <img
                src={pointV}
                alt="Fingapp Festival"
                className="absolute opacity-70 textBackground"
                style={{
                    left: "15%",
                    width: "2%",
                    bottom: "0",
                    zIndex: 0
                }}
            />
            <img
                src={pointV}
                alt="Fingapp Festival"
                className="absolute opacity-70 textBackground"
                style={{
                    left: "16%",
                    width: "2%",
                    bottom: "15%",
                    zIndex: 0
                }}
            />
            <img
                src={woman}
                alt="Fingapp Festival"
                className="absolute top-36 ajust-woman"
                style={{
                    zIndex: 2
                }}
            />
            <img
                src={fing}
                alt="Fingapp Festival"
                className="absolute ajust-fing"
            />
            <img
                src={textCircle}
                alt="Fingapp Festival"
                className="absolute top-28"
                style={{
                    right: "18%",
                    width: "14%",
                    zIndex: 0
                }}
            />
            <img
                src={retangleV}
                alt="Fingapp Festival"
                className="absolute -top-10 textBackground"
                style={{
                    right: "-3rem",
                    width: "8%",
                    zIndex: 10
                }}
            />
            <img
                src={block}
                alt="Fingapp Festival"
                className="absolute -bottom-28"
                style={{
                    right: "-17rem",
                    width: "25%",
                    zIndex: 0
                }}
            />
        </Pane>
    );
}