import { useState } from "react";
import Gabrielle from "../../assets/Gabrielle Amorim.jpeg";
import Jaime from "../../assets/Jaime Alheiros.jpeg";
import Lais from "../../assets/Laís Xavier.jpeg";
import Pamela from "../../assets/Pâmela Dias.jpeg";
import Rodrigo from "../../assets/Rodrigo Cunha.jpeg";
import Fransisco from "../../assets/WhatsApp Image 2024-11-13 at 10.26.26.jpeg";
import Emerson from "../../assets/Emerson Lima.jpeg";
import Miguel from "../../assets/Miguel Gaia.jpeg";
import Augusto from "../../assets/Augusto Galvão.jpeg";
import Leandro from "../../assets/Leonardo Leandro.jpeg";
import Andrea from "../../assets/Andrea  Gomes.jpeg";
import Lucio from "../../assets/Lúcio Ribeiro.jpeg";
import Yves from "../../assets/Yves Nogueira.jpeg";
import Joao from "../../assets/João Paulo Zica Fialho.jpeg";
import Gabriel from "../../assets/Gabriel Henriques.jpeg";
import Mayarra from "../../assets/WhatsApp Image 2024-11-13 at 14.45.03.jpeg";

export const useSpeakersHook = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedSpeaker, setSelectedSpeaker] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const speakers = [
        {
            id: 1,
            name: "Emerson Lima",
            informacoes: [
                { label: "Empresa", value: "Sauter" },
                { label: "Cargo", value: "Fundador e CEO" }
            ],
            image: Emerson
        },
        {
            id: 2,
            name: "Miguel Gaia",
            informacoes: [
                { label: "Empresa", value: "Governo de Pernambuco" },
                { label: "Cargo", value: "Secretário Executivo de Transformação Digital." }
            ],
            image: Miguel
        },
        {
            id: 3,
            name: "Laís Xavier",
            informacoes: [
                { label: "Empresa", value: "Assespro PE e PB" },
                { label: "Cargo", value: "Presidente" }
            ],
            image: Lais
        },
        {
            id: 4,
            name: "Augusto Galvão",
            informacoes: [
                { label: "Empresa", value: "CESAR" },
                { label: "Cargo", value: "Diretor Executivo" }
            ],
            image: Augusto
        },
        {
            id: 5,
            name: "Leonardo Leandro",
            informacoes: [
                { label: "Empresa", value: "Livelo" },
                { label: "Cargo", value: "Technical Product Manager" }
            ],
            image: Leandro
        },
        {
            id: 6,
            name: "Francisco Dias",
            informacoes: [
                { label: "Empresa", value: "Grupo Moura" },
                { label: "Cargo", value: "Gerente Geral de TI" }
            ],
            image: Fransisco
        },
        {
            id: 7,
            name: "Jaime Alheiros",
            informacoes: [
                { label: "Empresa", value: "SESI Pernambuco" },
                { label: "Cargo", value: "Coord de Transformação Digital" }
            ],
            image: Jaime
        },
        {
            id: 8,
            name: "Rodrigo Cunha",
            informacoes: [
                { label: "Empresa", value: "Neurotech" },
                { label: "Cargo", value: "Chief Product Officer e Sócio Fundador" }
            ],
            image: Rodrigo
        },
        {
            id: 9,
            name: "Andrea Gomes",
            informacoes: [
                { label: "Empresa", value: "Maya Labs" },
                { label: "Cargo", value: "Consultora de Impacto" }
            ],
            image: Andrea
        },
        {
            id: 10,
            name: "Lúcio Ribeiro",
            informacoes: [
                { label: "Empresa", value: "Porto Digital" },
                { label: "Cargo", value: "Empreendedor Residente" }
            ],
            image: Lucio
        },
        {
            id: 11,
            name: "Yves Nogueira",
            informacoes: [
                { label: "Empresa", value: "Porto Digital" },
                { label: "Cargo", value: "CSO da di2win, Softex PE e Conselheiro" }
            ],
            image: Yves
        },
        {
            id: 12,
            name: "João Paulo Zica Fialho",
            informacoes: [
                { label: "Empresa", value: "RAJA" },
                { label: "Cargo", value: "CEO" }
            ],
            image: Joao
        },
        {
            id: 13,
            name: "Gabrielle Amorim",
            informacoes: [
                { label: "Empresa", value: "iFood" },
                { label: "Cargo", value: "Engenheira de Software" }
            ],
            image: Gabrielle
        },
        {
            id: 14,
            name: "Pâmela Dias",
            informacoes: [
                { label: "Empresa", value: "Armazém da Criatividade" },
                { label: "Cargo", value: "Gestora de Inovação" }
            ],
            image: Pamela
        },
        {
            id: 15,
            name: "Gabriel Henriques",
            informacoes: [
                { label: "Empresa", value: "We Grow" },
                { label: "Cargo", value: "Sócio" }
            ],
            image: Gabriel
        },
        {
            id: 16,
            name: "Mayara Araújo",
            informacoes: [
                { label: "Empresa", value: "Salesforce" },
                { label: "Cargo", value: "Technical Consultant" }
            ],
            image: Mayarra
        },
        {
            id: 17,
            name: "João Moraes",
            informacoes: [
                { label: "Empresa", value: "Neurotech / B3" },
                { label: "Cargo", value: "Cientista de Dados Sênior" }
            ],
            image: null
        }
    ];

    const openModal = (speaker: any) => {
        setSelectedSpeaker(speaker);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSelectedSpeaker(null);
        setSelectedIndex(0);
    };

    return {
        isOpen,
        speakers,
        openModal,
        closeModal,
        selectedSpeaker,
        selectedIndex,
        setSelectedIndex
    }

}
