/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Heading, Pane, Text, Dialog, Tablist, Tab, Link, Avatar } from "evergreen-ui";
import { useSpeakersHook } from "../hooks/useSpeakersHook";
import triangle1 from "../../assets/triangulo superior.png";
import triangle2 from "../../assets/triangulo superior rotado.png";
import waves from "../../assets/onda retangulo.png";

export const Speakers: React.FC = () => {
    const {
        speakers
    } = useSpeakersHook();

    return (
        <Pane
            id="speakers"
            height="100vh"
            minHeight="120vh"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            background="white"
            className="snap-center overflow-hidden relative"
            padding={12}
        >
            <img
                src={triangle1}
                alt="Fingapp Festival"
                className="absolute -bottom-10 w-60"
                style={{
                    right: "-10px",
                }}
            />

            <img
                src={triangle2}
                alt="Fingapp Festival"
                className="absolute -top-10 w-60"
                style={{
                    left: "-10px",
                }}
            />

            <img
                src={waves}
                alt="Fingapp Festival"
                
                className="absolute -top-10 w-80"
                style={{
                    left: "-100px",
                    minHeight: "200vh"
                }}
            />

            <Heading zIndex={10} marginTop={24} textAlign="start" className="border-b" paddingY={8} size={800} width="56%" marginBottom={12}>Nossos Palestrantes</Heading>

            {/* Grid de cards */}
            <Pane
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                gap={24}
                maxWidth="80%"
                padding={8}
                style={{
                    scrollbarWidth: "none"
                }}
                className="overflow-y-auto"
            >
                {speakers.map((speaker) => (
                    <Pane
                        key={speaker.id}
                        elevation={1}
                        padding={8}
                        borderRadius={8}
                        background="#e5e7eb"
                        //onClick={() => openModal(speaker)}
                        cursor="pointer"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        width="230px"
                        height="230px"
                        className="relative mb-2"
                    >
                        {speaker.image ? (
                            <img
                                src={speaker.image}
                                alt={speaker.name}
                                style={{ width: '100%', height: "100%", borderRadius: 8, objectFit: "cover" }}
                            />
                        ) : (
                            <Avatar
                                name={speaker.name} // Mostra o nome no avatar
                                size={100} // Tamanho do avatar
                                marginBottom={50} // Espaçamento inferior
                            />
                        )}
                        <Heading
                            size={600}
                            borderRadius={3}
                            width="90%"
                            height="35%"
                            padding="8px"
                            textAlign="start"
                            color="white"
                            display="flex"
                            flexDirection="column"
                            bottom={-16}
                            right={-8}
                            background="#0d9488"
                            className="absolute"
                        >
                            <h1 style={{ fontSize: "14px", height: "25%" }}>{speaker.name}</h1>
                            <h1 color="#1e293b" className="text-slate-800" style={{ fontSize: "10px" }}>{speaker.informacoes[1].value}</h1>
                            <span className="text-xs mt-1 text-slate-50">{speaker.informacoes[0].value}</span>
                        </Heading>
                    </Pane>
                ))}
            </Pane>
        </Pane>
    );
};
