import { Button, IconButton, Link, MenuIcon, Pane, Paragraph, SideSheet } from "evergreen-ui";
import { useState } from "react";

export const Header: React.FC = () => {
    const [isShown, setIsShown] = useState(false)

    const handleScroll = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <Pane
            position="absolute"
            top={0}
            left={0}
            width="100%"
            padding={16}
            display="flex"
            justifyContent="start"
            zIndex={10}
        >
            <Pane className="sidebutton">
                <IconButton
                    icon={MenuIcon}
                    appearance="minimal"
                    size="medium"
                    background="white"
                    onClick={() => setIsShown(true)}
                />
            </Pane>

            <Pane className="hide-sidebar" position="relative" alignItems="center" justifyContent="center" gap={50} width="100%">
                <Link
                    cursor="pointer"
                    onClick={() => handleScroll("event")} padding={6}
                    style={{
                        color: "#f8fafc",
                        fontWeight: "500",
                        textDecoration: "none"
                    }}
                >
                    O Evento
                </Link>
                <Link
                    cursor="pointer"
                    onClick={() => handleScroll("speakers")}
                    padding={6}
                    style={{
                        color: "#f8fafc",
                        fontWeight: "500",
                        textDecoration: "none"
                    }}
                    marginRight={8}
                >
                    Palestrantes
                </Link>
                {/*<Link
                    cursor="pointer"
                    onClick={() => handleScroll("schedule")}
                    padding={6}
                    style={{
                        color: "#f8fafc",
                        fontWeight: "500",
                        textDecoration: "none"
                    }}
                >
                    Programação
                </Link>*/}
                <Link
                    cursor="pointer"
                    onClick={() => handleScroll("localization")}
                    padding={6}
                    style={{
                        color: "#f8fafc",
                        fontWeight: "500",
                        textDecoration: "none"
                    }}
                >
                    Localização
                </Link>
                <Link
                    cursor="pointer"
                    onClick={() => handleScroll("sponsors")}
                    padding={6}
                    style={{
                        color: "#f8fafc",
                        fontWeight: "500",
                        textDecoration: "none"
                    }}
                >
                    Realização
                </Link>
                <Link
                    href="https://www.sympla.com.br/evento/festival-de-inovacao-e-negocios-de-garanhuns-fing/2721170"
                    padding={4}
                    paddingX={24}
                    style={{
                        color: "#f8fafc",
                        background: "#0d9488",
                        fontWeight: "bold",
                        textDecoration: "none"
                    }}
                    marginLeft={80}>
                    Faça Sua Inscrição
                </Link>
            </Pane>


            <SideSheet isShown={isShown} position="left" width={300} onCloseComplete={() => setIsShown(false)}>
                <Pane
                    alignItems="center"
                    justifyContent="center"
                    gap={50}
                    width="100%"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    backgroundColor="#b45309"
                >
                    <Link
                        cursor="pointer"
                        onClick={() => handleScroll("event")}
                        padding={6}
                        width="50%"
                        style={{
                            color: "#f8fafc",
                            fontWeight: "500",
                            textDecoration: "none",
                            borderBottom: "2px solid transparent", // Borda invisível no estado normal
                            transition: "border-color 0.3s ease-out, transform 0.3s ease-out"
                        }}
                        onMouseEnter={(e: any) => (e.currentTarget.style.borderBottomColor = "#f8fafc")}
                        onMouseLeave={(e: any) => (e.currentTarget.style.borderBottomColor = "transparent")}
                    >
                        O Evento
                    </Link>
                    {/*<Link
                        cursor="pointer"
                        onClick={() => handleScroll("speakers")}
                        padding={6}
                        width="50%"
                        style={{
                            color: "#f8fafc",
                            fontWeight: "500",
                            textDecoration: "none",
                            borderBottom: "2px solid transparent", // Borda invisível no estado normal
                            transition: "border-color 0.3s ease-out, transform 0.3s ease-out"
                        }}
                        onMouseEnter={(e: any) => (e.currentTarget.style.borderBottomColor = "#f8fafc")}
                        onMouseLeave={(e: any) => (e.currentTarget.style.borderBottomColor = "transparent")}
                    >
                        Palestrantes
                    </Link>*/}
                    <Link
                        cursor="pointer"
                        onClick={() => handleScroll("schedule")}
                        padding={6}
                        width="50%"
                        style={{
                            color: "#f8fafc",
                            fontWeight: "500",
                            textDecoration: "none",
                            borderBottom: "2px solid transparent", // Borda invisível no estado normal
                            transition: "border-color 0.3s ease-out, transform 0.3s ease-out"
                        }}
                        onMouseEnter={(e: any) => (e.currentTarget.style.borderBottomColor = "#f8fafc")}
                        onMouseLeave={(e: any) => (e.currentTarget.style.borderBottomColor = "transparent")}
                    >
                        Programação
                    </Link>
                    <Link
                        cursor="pointer"
                        onClick={() => handleScroll("localization")}
                        padding={6}
                        width="50%"
                        style={{
                            color: "#f8fafc",
                            fontWeight: "500",
                            textDecoration: "none",
                            borderBottom: "2px solid transparent", // Borda invisível no estado normal
                            transition: "border-color 0.3s ease-out, transform 0.3s ease-out"
                        }}
                        onMouseEnter={(e: any) => (e.currentTarget.style.borderBottomColor = "#f8fafc")}
                        onMouseLeave={(e: any) => (e.currentTarget.style.borderBottomColor = "transparent")}
                    >
                        Localização
                    </Link>
                    <Link
                        cursor="pointer"
                        onClick={() => handleScroll("sponsors")}
                        padding={6}
                        width="50%"
                        style={{
                            color: "#f8fafc",
                            fontWeight: "500",
                            textDecoration: "none",
                            borderBottom: "2px solid transparent", // Borda invisível no estado normal
                            transition: "border-color 0.3s ease-out, transform 0.3s ease-out"
                        }}
                        onMouseEnter={(e: any) => (e.currentTarget.style.borderBottomColor = "#f8fafc")}
                        onMouseLeave={(e: any) => (e.currentTarget.style.borderBottomColor = "transparent")}
                    >
                        Realização
                    </Link>
                    <Link
                        href="https://www.sympla.com.br/evento/festival-de-inovacao-e-negocios-de-garanhuns-fing/2721170"
                        padding={10}
                        paddingX={24}
                        width="60%"
                        style={{
                            color: "#f8fafc",
                            background: "#0d9488",
                            fontWeight: "bold",
                            textDecoration: "none"
                        }}
                    >
                        Faça Sua Inscrição
                    </Link>
                </Pane>
            </SideSheet>
        </Pane>
    );
};
