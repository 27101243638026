import { useState, useEffect, useRef } from "react";
import { Pane, IconButton, ArrowUpIcon, Tooltip } from "evergreen-ui";
import { Header } from "./components/Header";
import { Schedule } from "./components/Schedule";
import { Speakers } from "./components/Speakers";
import { Event } from "./components/Event";
import { Localization } from "./components/Localization";
import { Init } from "./components/Init";
import { Sponsors } from "./components/Sponsors";

export const Home: React.FC = () => {
    const [showScrollTopButton, setShowScrollTopButton] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    // Função para voltar ao topo
    const scrollToTop = () => {
        containerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    };

    // Controla a visibilidade do botão de "Voltar ao Topo" ao rolar o contêiner
    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const scrollPosition = containerRef.current.scrollTop;
                setShowScrollTopButton(scrollPosition > window.innerHeight * 0.5);
            }
        };

        const currentContainer = containerRef.current;
        if (currentContainer) {
            currentContainer.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (currentContainer) {
                currentContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return (
        <Pane
            ref={containerRef}  // Adiciona a referência do contêiner
            className="flex-col w-full overflow-y-scroll h-screen snap-y"
            paddingTop={56}
            style={{
                scrollbarWidth: "none",
                //  position: "relative",
            }}
        >
            <Header />
            <Init />
            <Event />
            <Speakers />
            {/*<Schedule /> */}
            <Localization />
            <Sponsors />

            {/* Botão "Voltar ao Topo" */}
            {showScrollTopButton && (
                <Tooltip content="Voltar ao topo" position="left">
                    <IconButton
                        icon={ArrowUpIcon}
                        onClick={scrollToTop}
                        appearance="primary"
                        intent="success"
                        size="large"
                        position="fixed"
                        bottom={24}
                        right={24}
                        zIndex={1000}
                    />
                </Tooltip>
            )}
        </Pane>
    );
};
