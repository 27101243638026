/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { Heading, Pane, Text } from "evergreen-ui";
import logo from "../../assets/logos no retangulo.png";
import sg from "../../assets/10.png";
import paint from "../../assets/Logo Paint agencia digital.png";
import sauter from "../../assets/Logo Simplicado - Roxo.png";
import cesar from "../../assets/logoCESAR_Prancheta 1 cópia.png";
import cantadora from "../../assets/MARCA D_AGUA 4.png";
import assepro from "../../assets/marca-assespro-pe-e-pb.png";
import pontetech from "../../assets/PonteTech + Adepe Porto Digital-01.png";
import raja from "../../assets/RAJA_VERTICAL_PRETO_NO_TRANSP.png";
import secti from "../../assets/SECTI.png";
import usinasecti from "../../assets/USINA l SECTI.png";
import patrocinadores from "../../assets/Festival de Inovação e Negócios de Garanhuns.png";

export const Sponsors: React.FC = () => {
    // Array de imagens dos patrocinadores
    //const sponsorLogos = [logo, sg, paint, sauter, cesar, cantadora, assepro, pontetech, raja, secti, usinasecti]; // substitua com URLs reais

    return (
        <Pane
            id="sponsors"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="snap-center relative loc bg-gradient-to-r from-gray-400 to-gray-200 p-4 shadow-xl"
            style={{ gap: "2rem", maxWidth: "100%", margin: "0 auto" }}
        >
            {/* Lado Esquerdo - Informações */}
            <Pane width="40%" height="100%" className="text-left text-white space-y-4 flex items-start justify-center flex-col">
                <Heading
                    size={900}
                    className="neon-title mb-4"
                    style={{
                        fontSize: "2.5rem",
                        letterSpacing: "0.1em",
                        color: "#e0e0ff",
                    }}
                >
                    Realização
                </Heading>
                <Text size={500} marginTop={15} className="text-gray-900">
                    Nossos patrocinadores tornam este evento possível. Apoiando nossa missão, eles ajudam a promover inovação e crescimento na nossa comunidade.
                </Text>
                <Text size={400} className="text-gray-900 italic">
                    Agradecemos a todos pelo apoio e confiança!
                </Text>
            </Pane>

            {/* Lado Direito - Galeria de Logos */}
            <Pane
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                //gap="1.5rem"
                width="50%"
                height="100%"
                className="logo-gallery"
            >
                {/*{sponsorLogos.map((logoSrc, index) => (
                    <Pane
                        key={index}
                        className="sponsor-card"
                        style={{
                            width: "8rem",
                            height: "3rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "12px",
                            //backgroundColor: "rgba(255, 255, 255, 0.4)",
                            backdropFilter: "blur(5px)",
                            border: "1px solid rgba(255, 255, 255, 0.1)",
                            padding: "0.5rem",
                        }}
                    >
                        <img
                            src={logoSrc}
                            alt={`Patrocinador ${index + 1}`}
                            className="object-contain w-full h-full sponsor-image"
                        />
                    </Pane>
                ))}*/}

                <img
                    src={patrocinadores}
                    className="object-contain w-full h-full sponsor-image"
                />
            </Pane>
        </Pane>
    );
};
