import { Button, Heading, Link, Pane, Text } from "evergreen-ui";
import manImage from "../../assets/imagem homem.png";

export const Event: React.FC = () => (
    <Pane
        id="event"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className="snap-center relative overflow-hidden"
        style={{
            position: "relative",
            width: "100%",
            background: "linear-gradient(to left, #DC5A00, rgba(220, 90, 0, 0))"
        }}
    >
        <img
            src={manImage}
            alt="Fingapp Festival"
            className="absolute ajust-man"
        />

        <div className="relative ajust-event-text">
            <h1 color="white" className="text-title">Festival de Inovação e Negócios de Garanhuns 2024</h1>
            <p color="white" className="text-event">
                <br />
                O evento que vai contectar você ao futuro dos negócios no Agreste Meridional <br /><br />
                O Festival acontecerá em 07 de dezembro de 2024, no SESC Garanhuns, PE, e contará com 6 espaços temáticos repletos de dinâmicas interativas e uma programação diversificada. Mais de 1.000 pessoas são esperadas para esse encontro que une startups, empresas e artistas locais em um ambiente colaborativo e inovador. <br /><br />
                Serão palestras inspiradoras, cases de sucesso e exposições que mostrarão o melhor da inovação e empreendedorismo no cenário local e estadual, com muita cultura e arte para integrar as raízes regionais à modernidade do setor. Não perca essa oportunidade única de transformar seu negócio!
            </p>
            <Link
                href="https://www.sympla.com.br/evento/festival-de-inovacao-e-negocios-de-garanhuns-fing/2721170"
                cursor="pointer"
                position="absolute"
                marginTop={40}
                left="0"
                padding={12}
                paddingX={28}
                marginLeft="30%"
                style={{
                    color: "#f8fafc",
                    background: "#0d9488",
                    fontWeight: "bold",
                    textDecoration: "none",
                    fontSize: "14spx"
                }}
            >
                Faça Sua Inscrição
            </Link>
        </div>
    </Pane>
);
